<template>
  <div>
    <el-dialog title="SDK鉴权配置" :visible.sync="visible" width="50%" :before-close="handleClose" @open="getData">
      <div>
        <el-form v-loading="tableLoading" label-width="110px" size="small" class="form" ref="form" :model="form" :rules="rules">
          <el-form-item label="类型:" prop="clientType">
            <el-select v-model="form.clientType" placeholder="请选择" @change="getData">
              <el-option label="企业微信" :value="0">
              </el-option>
              <el-option label="钉钉" :value="1">
              </el-option>
              <el-option label="微信小程序" :value="3">
              </el-option>
              <el-option label="微信公众号" :value="4">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业ID:" prop="clientId">
            <el-input v-model="form.clientId"></el-input>
          </el-form-item>
          <el-form-item label="应用ID:" prop="agentId">
            <el-input v-model="form.agentId"></el-input>
          </el-form-item>
          <el-form-item label="应用Secret:" prop="clientSecret">
            <el-input v-model="form.clientSecret"></el-input>
          </el-form-item>
          <el-form-item label="状态:" prop="enabled">
            <el-switch v-model="form.enabled" active-text="启用" inactive-text="禁用" active-color="#13ce66" inactive-color="#f4f4f4" :active-value="true" :inactive-value="false">
            </el-switch>
          </el-form-item>
          <!-- <el-form-item label="回调地址:" prop="redirectUri">
						<el-input v-model="form.redirectUri"></el-input>
					</el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableLoading: false,
      form: {
        agentId: ""
      },
      rules: {
        agentId: [{
          required: true,
          message: '请输入agentId',
          trigger: 'change'
        }],
        clientId: [{
          required: true,
          message: '请输入clientId',
          trigger: 'change'
        }],
        clientSecret: [{
          required: true,
          message: '请输入clientSecret',
          trigger: 'change'
        }],
        clientSecret: [{
          required: true,
          message: '请输入clientSecret',
          trigger: 'change'
        }],
        clientType: [{
          required: true,
          message: '请输入clientType',
          trigger: 'change'
        }],
        redirectUri: [{
          required: true,
          message: '请输入redirectUri',
          trigger: 'change'
        }],
      }
    }
  },
  props: {
    thirdPartyConfigId: {
      type: String | Number,
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
  },
  methods: {
    getData(e) {
      // this.form={clientType:e};
      this.tableLoading = true;
      this.$get('/platform-admin/thirdPartyAuthorization/info', {
        thirdPartyConfigId: this.thirdPartyConfigId,
        clientType: e || 0,
      }).then(res => {
        if (res && res.code == 0) {
          this.form = res.data || {
            clientType: e
          }
        }
        this.$refs.form.resetFields();
        this.tableLoading = false;
      })
    },
    // 保存
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.thirdPartyConfigId = this.thirdPartyConfigId
          this.$post('/platform-admin/thirdPartyAuthorization/saveOrUpdateThirdPartyAuthorization', this.form).then(res => {
            if (res && res.code == 0) {
              this.$message.success("保存成功");
              this.handleClose()
            }
          })
        }
      });
    },
    handleClose() {
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
